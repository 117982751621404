import jcssearchclient from "@jcs/http-client";
import instantsearch from "instantsearch.js";
import {
  refinementList,
  infiniteHits,
  rangeSlider,
} from "instantsearch.js/es/widgets";
import { autocomplete, getAlgoliaResults } from "@jcs/autocomplete-js";

import "@algolia/autocomplete-theme-classic";
import "instantsearch.css/themes/satellite.css";

const searchClient = jcssearchclient({
  baseURL: "https://jcs-backend.vercel.app/api",
});

const search = instantsearch({
  indexName: "demo_ecommerce",
  searchClient,
});

search.addWidgets([
  refinementList({
    container: "#my-team",
    attribute: "team",
  }),
  refinementList({
    container: "#my-projects",
    attribute: "projects",
  }),
  infiniteHits({
    container: document.querySelector("#result-list"),
    templates: {
      item(hit, { html, components }) {
        return html`
          <h3>${hit.title}</h3>
          <p>${": " + hit.description}</p>
        `;
      },
    },
  }),
  rangeSlider({
    container: document.querySelector("#project-cost"),
    attribute: "project-cost",
  }),
  rangeSlider({
    container: document.querySelector("#employee-salary"),
    attribute: "employee-salary",
  }),
]);

autocomplete({
  container: "#autocomplete-test",
  getSources() {
    return [
      {
        sourceId: "querySuggestions",
        getItemInputValue: ({ item }) => {
          return item.query;
        },
        getItems({ query }) {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: "instant_search_demo_query_suggestions",
                query,
                params: {
                  hitsPerPage: 4,
                },
              },
            ],
          });
        },
        templates: {
          item({ item, components }) {
            console.log({ item });
            return components.ReverseHighlight({
              hit: item,
              attribute: "query",
            });
          },
        },
      },
    ];
  },
});

search.start();
